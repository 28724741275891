import React from 'react';
import { PageProps } from 'gatsby';
import Breadcrumb from '../components/Layout/breadcrumb';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Meta from '../components/Layout/head';
import Layout from '../components/Layout/layout';
import ObfuscationLink from '../components/seo/ObfuscationLink';

import media from '../images/photos/cascade-de-la-fraiche.jpg';

export const Head = () => (
    <Meta 
        title={`Contact`} 
        description={`Une question, un partenariat, une demande d'ajout d'images ? Contactez-nous ! Nous vous répondrons dès que possible !`}
        lang='fr'
        media={media}
        pathname="/contact/"
    > 
    </Meta>
)

// https://app.getform.io

export default function ContactPage(location: PageProps['location']) {

    const breadcrumbArray = [['/contact/','Contact']]; 
    const GatsbyRecaptchaKey = process.env.GATSBY_RECAPTCHA_KEY ? process.env.GATSBY_RECAPTCHA_KEY : '';
    
    return (
        <Layout>
            <GoogleReCaptchaProvider
                reCaptchaKey={GatsbyRecaptchaKey}
                language={"fr"}
                scriptProps={{
                async: true, // optional, default to false,
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                }}
            >
                <Container fluid className='position-relative'>
                    <Row>
                        <Col lg='6'>
                            <img className='contact-column-img deleteTablet' alt="Cascade de la Fraîche - Photographié par Rheaparks" src={media} />
                        </Col>
                    </Row>
                    <Row className='row-absolute'>
                        <Col className='position-relative' lg={{span:5,offset:1}}>
                            {/* <div className='left-column-absolute'>
                                <div className='white-breadcrumb d-flex flex-row-reverse'>
                                    <Breadcrumb itemsArray={breadcrumbArray} />
                                </div>
                                <h1 className='size-xxl text-white'>Envoyez-nous <b>un message</b></h1>
                                <p className='size-sm text-white'>Que vous ayez une question, souhaitiez réaliser un partenariat avec nous ou aimeriez partager vos photos au cœur de l’application, n’hésitez pas à nous contacter. Nous vous répondrons au plus vite. Merci !</p>
                            </div> */}
                        </Col>
                        <Col className='me-5 ms-4 green'>
                            <div>
                                <Breadcrumb location={location} itemsArray={breadcrumbArray} />
                            </div>
                            <h1 className='size-xxl'>Envoyez-nous <b>un message</b></h1>
                            <p className='size-sm'>Que vous ayez une question, souhaitiez réaliser un partenariat avec nous ou aimeriez partager vos photos au cœur de l’application, n’hésitez pas à nous contacter. Nous vous répondrons au plus vite. Merci !</p>
                            <p>Pour nous envoyer un e-mail, c'est par ici :</p>
                            <ObfuscationLink encodeUrl="mailto:contact@rheaparks.com">
                                <div className='btn btn-orange btn-lg'>
                                    <span>Contacter</span>
                                </div>
                            </ObfuscationLink>
                            {/* <Form className='right-column-absolute' action='https://getform.io/f/8c2391b0-4c15-462d-8093-894b30bfc8c7' method='POST' encType='multipart/form-data'>
                                
                                <Form.Control type="hidden" id="captchaResponse" name="g-recaptcha-response" />

                                <Form.Group as={Col} className='mb-3'>
                                    <Form.Label>Sujet</Form.Label>
                                    <Form.Select name='subject'>
                                        <option value="Je souhaite…">Je souhaite…</option>
                                        <option value="Donner un conseil pour l'app">Donner un conseil pour l'app</option>
                                        <option value="Partager mes photos sur Rheaparks">Partager mes photos sur Rheaparks</option>
                                        <option value="Réaliser un partenariat (blogueur, influenceur, etc.)">Réaliser un partenariat (blogueur, influenceur, etc.)</option>
                                        <option value="Obtenir un renseignement">Obtenir un renseignement</option>
                                        <option value="Faire une réclamation">Faire une réclamation</option>
                                        <option value="Autre">Autre</option>
                                    </Form.Select>
                                </Form.Group>

                                <Row className="mb-3">
                                    <Col>
                                        <Form.Group as={Col}>
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control 
                                                placeholder="Nom"
                                                autoComplete="family-name"
                                                name="lastname"
                                                type="text"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group as={Col}>
                                            <Form.Label>Prénom</Form.Label>
                                            <Form.Control 
                                                placeholder="Prénom" 
                                                autoComplete="given-name"
                                                name="firstname"
                                                type="text"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group as={Col} className='mb-3'>
                                    <Form.Label>Adresse email</Form.Label>
                                    <Form.Control 
                                        type='email' 
                                        placeholder='jean-gideon@example.com'
                                        autoComplete='email'
                                        name='email' 
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control 
                                        as="textarea"  
                                        rows={4}
                                        name="message" 
                                        type="text"
                                        required
                                    />
                                </Form.Group>

                                <Button className="btn btn-orange float-right" size="lg" type="submit">
                                    Envoyer
                                </Button>
                            </Form> */}
                        </Col>
                    </Row>
                </Container>
            </GoogleReCaptchaProvider>
        </Layout>
    )
}